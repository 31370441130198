<template>
    <div>
         <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				<b-col sm="12" md="4">
					<label>Estado(s) de formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>
            </b-row>
		</b-card>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>                      
                </b-row>
            </div>
            
            <b-table
                ref="refFormListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 250px' : 'height: inherit'"
                :items="fetchForms"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(month_and_year)="data">
                    {{ getDateFormat(data.item.start_date, true) }}                  
                </template>

                <template #cell(state)="data">     
                    <b-badge :variant="`light-${data.item.state.color}`">
                        {{ data.item.state.name }}
                    </b-badge>
                </template>
                
                <template #cell(start_date)="data">
                    <b-col style="width: 200px">
                        {{ getDateFormat(data.item.start_date) }} 00:00:00
                    </b-col>
                </template>
                
                <template #cell(deadline)="data">
                    <b-col style="width: 200px">
                        {{ getDateFormat(data.item.deadline) }} 23:59:59
                    </b-col>
                </template>

                <template #cell(observations_count)="data">
                    {{ data.item.state.id === States.COMPLETADO ? data.item.observations_count : '' }}
                </template>

                <template #cell(observations)="data">
                    <b-button
                        v-if="data.item.state.id === States.COMPLETADO && data.item.observations_count > 0"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        @click="viewObservations(data.item.id)"
                        variant="outline-primary"
                    >
                        Ver Observaciones
                    </b-button>
                </template> 
                
                <template #cell(report_download)="data">
                    <template v-if="data.item.state.id === States.COMPLETADO">
                       <b-button
                            v-if="data.item.report_storage"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            block
                        >
                            <b-link 
                                @click='viewFormFile(data.item.id, "report_storage")'
                            >
                                <feather-icon icon="DownloadIcon" /> Informe
                            </b-link>
                        </b-button>                     
                                        
                       <b-button
                            v-if="data.item.score < 95 && data.item.memo_storage"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            block
                        >
                            <b-link
                                @click='viewFormFile(data.item.id, "memo_storage")'
                            >
                                <feather-icon icon="DownloadIcon" /> Memorándum
                            </b-link>
                        </b-button>                     
                    </template>
                </template> 
                
                <template #cell(options)="data">
                    <b-button
                        block
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"                            
                        style="line-height: 1.3rem;"          
                        :to="{ name: 'provider-form-edit', params: { id: data.item.id }, query: { form: data.item}}"                             
                        :variant="`outline-${[States.EN_CURSO, States.POR_REALIZAR].includes(data.item.state.id) ? 'success' : 'dark'}`"
                        :disabled="[States.EN_CURSO, States.POR_REALIZAR].includes(data.item.state.id) ? false : true"
                        @click="updateState(data.item)"                         
                    >   
                        <feather-icon :icon="getButtonState(data.item.state.id).icon" />
                        {{ getButtonState(data.item.state.id).text }}
                    </b-button>   
                </template>
                
            </b-table>
        
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal
            ref="refObservationsModal"
            size="lg"
            ok-title="ACTUALIZAR"
            cancel-title="CANCELAR"
            cancel-variant="outline-secondary"
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="OBSERVACIONES"
            centered
            no-close-on-backdrop
            @ok="onSubmit"
        >            
            <b-form-group class="mt-1">
                <b-form-checkbox
                    v-for="observation in observations"
                    :key="observation.id"
                    :value="observation.id"
                    v-model="selectedObservations"
                    name="flavour-3a"
                >
                    {{ observation.text }}  
                </b-form-checkbox>
            </b-form-group>
        </b-modal>        
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { onUnmounted, reactive } from '@vue/composition-api';
    import { VBTooltip, VBModal } from 'bootstrap-vue';
    import formStoreModule from '../formStoreModule';
    import useFormsList from './useFormsList';
    import Ripple from 'vue-ripple-directive';
    import { States } from '@core/utils/data';
    import vSelect from 'vue-select';
    import store from '@/store';
    import moment from 'moment';
    import axios from '@axios';

    export default {
        components: {  
            vSelect
        },
        directives: {
            'b-modal': VBModal,            
            'b-tooltip': VBTooltip,
            Ripple
        },
        data() {                              
            return {
                States,
                
                formId2: null,
                formId: null,                
                filter: null,
                selectedObservations: [],
                observations: [],
                moment           
            }
        },
        methods: {
            async viewObservations (formId) {
                let response = await axios.get(`/form/${formId}/observations`);
                this.observations = response.data;
                this.formId2 = formId;
                this.selectedObservations = [];

                this.observations.map(item => {
                    if (item.check) this.selectedObservations.push(item.id);
                });

                this.$refs['refObservationsModal'].show();
            },
            async onSubmit () {
                let data = this.selectedObservations.join(',');
                let response = await axios.put('/observations/update', { data, formId: this.formId2 });

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

            },
            async updateState (data) {
                const formId = data.id;
                const state_id = data.state.id;
                
                if(state_id == 3) await axios.put(`/form/${formId}/update/state`, { state_id } );                    
            },  
            getButtonState (state_id) {
                let icon = 'EditIcon', text = 'Iniciar Formulario'
                if (state_id === this.States.EN_CURSO) text = 'Completar Formulario'
                if (state_id === this.States.COMPLETADO) {
                    icon = 'CheckSquareIcon'
                    text = 'Formulario Revisado'
                }
                if (state_id === this.States.NO_REALIZADO) { 
                    icon = 'XCircleIcon'
                    text = 'Formulario Sin Revisión'
                }
                if (state_id === this.States.EN_REVISION) {
                    icon = 'ClockIcon'
                    text = 'En Revisión Formulario'
                }
                return { icon, text }
            },
            getDateFormat (date, subtract) {
                if (!date) return ''
                let dateFormat = moment(date).format('DD-MM-YYYY') 
                if (subtract) dateFormat = moment(date).subtract(1, 'months').format('MM/YYYY') 
               return dateFormat
            }
        },
        setup() {

            const USER_APP_STORE_MODULE_NAME = 'app-provider-form';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
            
            const optionsStates = reactive([]);        
                
            axios.get('/state/select')
                .then(response => {
                    if(response.data){                        
                        response.data.map( item => {
                            optionsStates.push({
                                label : item.name, 
                                value : item.id
                            });
                        });   
                    }                 
                });
            

            const viewFormFile = (id, column) => {

                const payload = {
                    column
                }

                store.dispatch('app-provider-form/viewFormFile', { id, payload })
                    .then( response => {

                        window.open(response.data.url, '_blank');

                    })
                    .catch( () => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al descargar el archivo',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    });

            };

            const {
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,
                
                filterState
            } = useFormsList();

            return {                
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,       

                // FILTERS                
                optionsStates,
                filterState,

                // METHODS
                viewFormFile
            }
        }
    }

</script>

<style lang="scss" scoped>

    .per-page-selector {
        width: 90px;
    }    

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>