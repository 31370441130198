import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import router from '@/router';

export default function useFormsList() {
    // Use toast
    const toast = useToast();

    const refFormListTable = ref(null);
    const styleColumns = {
        thClass: 'text-center', 
        tdClass: 'text-center'
    }
    // Table Handlers
    let tableColumns = [
        { key: 'month_and_year', sortable: false, label: 'Mes/Año', ...styleColumns },
        { key: 'state', sortable: true, label: 'Estado', ...styleColumns },
        { key: 'start_date', sortable: true, label: 'Fecha de Inicio', ...styleColumns },
        { key: 'deadline', sortable: true, label: 'Fecha Límite', ...styleColumns },
        { key: 'report_download', sortable: false, label: 'Descargar Informe', ...styleColumns },
        { key: 'options', label: 'Opciones', ...styleColumns },
    ];

    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref('start_date');
    const isSortDirDesc = ref(true);

    const filterState = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refFormListTable.value ? refFormListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });

    const refetchData = () => {
        refFormListTable.value.refresh();
    };

    watch([currentPage, perPage, filterState], () => {
        refetchData();
    });

    const fetchForms = (ctx, callback) => {

        let filterStateArray = [];
        if (filterState.value) {
            filterState.value.map(item => {
                filterStateArray.push(item.value);
            });
        }

        store
            .dispatch('app-provider-form/fetchForms', {
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterState: filterState.value ? filterStateArray.join(',') : null
            })
            .then(response => {
                const { users, total } = response.data;
                callback(users);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de formularios',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchForms,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refFormListTable,

        refetchData,

        filterState
    }
}